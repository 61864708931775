<template>
  <div class="text-h3 text-center pb-4">{{ title }}</div>
</template>

<script>
export default {
  props: ['title'],
  data: () => ({})
}
</script>
